.card {
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  width: 300px;
  padding: 16px;
  margin: 16px 4px;
  background: rgba($black, 0.25);
  color: $white;
  border-radius: 6px;
  box-shadow: 5px 5px 8px $black;

  &.white-background-card {
    background: rgba($red, 0.25);
    box-shadow: 5px 5px 8px $black;
    color: $red;
  }

  h2 {
    font-weight: 1000;
    font-size: 20px;
  }

  h3 {
    font-weight: 300;
    font-size: 14px;
  }

  h4 {
    font-size: 12px;
    font-weight: 100;
    font-style: italic;
  }

  a:visited {
    color: $white;
  }
  img {
    width: 300px;
    height: 180px;
  }
}
