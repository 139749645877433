@import '/navbar/navbar.scss';


.header {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
  left: 0;
  right: 0;
  padding: 20px;
  font-size: 24px;

  .info-container {
    display: flex;
    justify-content: center;
    vertical-align: baseline;
    align-items: center;

    @media(max-width: 576px) {
      flex-direction: column;
    };
  }

  .andrew-head-cropper {
    position: relative;
    overflow: hidden;
    width: 150px;
    min-width: 150px;
    height: 150px;
    min-height: 150px;
    border-radius: 50%;
    background-color: $white;
    border: 2px solid $white;

    .andrew-head {
      position: relative;
      width: 175px;
      height: 175px;
      margin-top: -20px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-left: -22px;
    }

    p {
      position: absolute;
      left: 0;
      right: 0;
      top: 25%;
    }

  }

  .andrew-title {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    text-align: left;


    h1 {
      font-family: 'Quicksand';
      font-weight: 600;
      margin-bottom: 0;
    }

    h2 {
      margin-top: 4px;
      font-size: 28px;
    }

    .title-placeholder {
      margin-top: 4px;
      min-height: 36px;
    }


    @media(max-width: 576px) {
      text-align: center;
      margin-left: 0;

      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1em;
      }
    };

  }

  .disable-link {
    pointer-events: none;
  }

  .clickable {
    cursor: pointer;
    transition: opacity .25s;

    &:hover {
      opacity: 0;
      transition: opacity .25s;
    }
  }
}