.contact-methods {
  display: flex;
  justify-content: space-evenly;
}


a {
  color: $white;
  text-decoration: none;


  &:hover {
    text-decoration: underline;
  }
}
.contact-form {
  width: 100%;
  h1 {
    margin-bottom: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 48px;
  }

  #contact {
    width: 100%;
  }



  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 12px;
    line-height: 30px;
  }

}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,600,400italic);

.container {
  width: 100%;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 800px;
  padding-bottom: 50px;


  @media(max-width: 576px) {
    width: 80%;
  }
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
  display: flex;
}

.contact-button-container {
  width: 100%;
  display:flex;
  justify-content: center;
  text-align: center;
  button {
    display: flex;
    justify-content: center;
  }
}

#contact {
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  margin-bottom: 50px;

}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 16px;
  padding: 16px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  resize: none;
}

#contact button {
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  border: none;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
  transition: background-color .25s;
  text-align: center;

  &:hover {
    transition: background-color .25s;
  }
}

#contact button[type="submit"]:hover {
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

label {
  color: inherit;
}
#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

.green-background-contact{
  &#contact {
    background: rgba($black, 0.25);
  }

  #contact-submit {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $white;
      color: $green;
    }
  }

  #contact-submit:hover {
    background: $white;
  }
}

.white-background-contact {
  &#contact {
    background: rgba($red, 0.25);
  }

  #contact-submit {
    background-color: rgba($red, 0.50);
    color: $white;
    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  #contact-submit:hover {
    background: $red;
  }
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}