.navbar-container {
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    max-width: 800px;
    width: 100%;
    padding-left: 0;

    li {
      a {
        text-decoration: none;
        font-size: 18px;
        transition: font-size .25s; 
        padding: 24px;

        &:hover {
          font-size: 24px;
          transition: font-size .25s; 
        }

        &:visited, &:link {
          color: inherit;
        }

        &.active {
          font-size: 24px;
          font-weight: 600;
        }


      }
    }
  }

}