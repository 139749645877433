
 .skills {
  text-align: left;

  h4 {
    text-align: left;
    font-weight: lighter;
  }
  span {
    font-size: 18px;
    font-weight: 800;
  }
}

.about {
  display: flex;
  @media(max-width: 576px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 40px;
    padding-top: 40px;

    h1 {
      font-size: 48px;
      font-weight: bolder;
    }

    p {
      font-size: 1.5em;
      margin-left: 16px;
    }

    @media(max-width: 576px) {
      padding-left: 0px;
    }
  }

  .profile {
    width: 40%;
    min-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(max-width: 576px) {
      width: 90%;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
    }

  }



 

  .about-image-cropper {
      // position: relative;
      overflow: hidden;
      width: 250px;
      min-width: 250px;
      height: 250px;
      min-height: 250px;
      border-radius: 50%;
      background-color: $white;
      border: 2px solid $white;
      margin: 20px;
      // margin-top: 48px;

    .about-image{
      position: relative;
      height: 250px;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: 5px;
    }
  }

}

.white-background-font {
  .about {
    .profile {
      .about-image-cropper {
        background-color: $red;
        border: 2px solid $red;
      }
    }
  }
}