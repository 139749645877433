@import-normalize;
@import '/styles/_colors.scss';
@import '/splashLinks/splashLinks.scss';
@import '/header/header.scss';
@import '/page/page.scss';

*{
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $red;
  width: 100vw;
}

.App {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

.fadeOut {
  opacity: 0;
  // transition: opacity .25s;
}


.fadeIn {
  opacity: 100;
  transition: opacity 2s;
}