@import '../styles/_colors.scss';


.splash-links {
  display: flex; 
  justify-content: space-between;
  height: 100%;

  .splash-link {
    display: flex;
    height: 100%;
    width: 100%;
    box-shadow: 5px 5px rgba(0,0,0,0.25);

    &.unexpanded {
      height: 100%;
      max-height: 100%;
      
        width: 34%;
        transition: width .5s;

        &:hover {
          width: 50%;
          transition: width .5s;
        }
    }
    

    &.expanded {
      width: 100%;
      opacity: 1;
      transition: width .5s;
    }

    &.flat {
      width: 0%;
      transition: width .5s;
    }
    a {
      width: 100%;
      height: 100%;

      button {
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
        text-align: center;

        &:hover {
          cursor: pointer;
          
          h2 {
            font-size: 32px;
            font-weight: 400;
            transition: font-size 0.25s;
          }
          
        }
        
        &.clicked {
          cursor: default;
          
          h2 {
            font-size: 28px;
            font-weight: bold;
            margin: -300px 10px 0 0;

            // margin-top: -370px;

            // &.engineer {
            //   margin-left: 165px;
            // }
            // &.music {
            //   margin-left: 34px;
            // }
            // &.nerd {
            //   margin-right: 28px;
            // }

            transition: opacity .5s, margin 3s;
          }
        }
        
        h2 {
          font-family: 'Quicksand';
          font-size: 24px;
          margin-right: 10px;
          transition: font-size 0.25s, margin .5s;
          font-weight: 100;
        }

      } 
      
      @media(max-width: 576px) {
        button {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          padding-bottom: 80px;
        }
      }
    }
  }
}
;