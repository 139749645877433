@import '/about/about.scss';
@import '/contact/contact.scss';
@import '/portfolio/portfolio.scss';

.page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - 280px);
  position: absolute;
  z-index: 500;
  top: 280px;
  bottom: 0;

  @media(max-width: 576px) {
    top: 380px;
    height: calc(100% - 380px);
  }
}

.page {
  width: 90%;
  display:flex;
  justify-content: center;
  overflow-y: scroll;
  scrollbar-width: none;

  span {
    width: 80%;
  }

  @media(max-width: 576px) {
    width: 100%;

    span {
      width: 100%;
    }
  }
}

.fade-enter {
  opacity: 0.01;
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.fade-leave {
  opacity: 1;

  &.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}
